import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      whiteLabel: 'GET_WHITE_LABEL_DATA',
      marketerId: 'GET_MARKETER_ID',
      marketerAgencyName: 'GET_MARKETER_AGENCY',
      marketerAgencyContent: 'GET_MARKETER_AGENCY_CONTENT',
      isMarketerSite: 'GET_IS_MARKETER_SITE',
    }),
    isAgencySite() {
      return this.marketerId !== '' && this.marketerId !== null;
    },
    kindTheme() {
      let theme = 0;
      const { name } = this.$route;
      const dmstcData = this.marketerAgencyContent?.marketerSpecificContents ?? null;
      if (dmstcData?.useAlternateTemplate) {
        let templateData = null;
        switch (name) {
          case 'home':
            templateData = dmstcData.pageTemplates.find((item) => item.page === 0);
            theme = templateData?.selectedTemplate ?? 0;
            break;
          case 'search-result':
            // templateData = dmstcData.pageTemplates.find((item) => item.page === 1);
            // theme = templateData?.selectedTemplate ?? 0;
            break;
          case 'product-page':
            // templateData = dmstcData.pageTemplates.find((item) => item.page === 2);
            // theme = templateData?.selectedTemplate ?? 0;
            break;
          default:
            theme = 0;
        }
      } else {
        theme = 0;
      }
      // theme = this.isMarketerSite ? 1 : 0;
      return theme;
    },
    hostData() {
      let currHostData = null;
      if (this.isAgencySite) {
        currHostData = this.marketerAgencyContent;
      } else if (this.whiteLabel && !this.whiteLabel.forMainSite) {
        currHostData = this.whiteLabel;
      }
      return currHostData;
    },
  },
};
