<template>
  <div class="st-pusher">
    <div class="st-content" ref="main">
      <div class="st-content-inner">
        <div class="main clearfix">
          <!-- <WhiteHeader/> -->
          <!-- <NavigationHeader/> -->
          <TopHeader/>
          <NavigationHeader v-if="device === 'desktop'"/>
          <TopNav :kindTheme="kindTheme" v-if="device === 'mobile'"/>
          <NavMenu/>
          <LandingContent :preferDestCode="preferDestCode" :preferDealType="preferDealType"/>
          <Footer/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import bannerImageDestination from '@/utils/bannerImageDestination';
import mixinTheme from '@/components/mixins/mixinTheme';

export default {
  name: 'LandingPage',
  components: {
    NavMenu: () => import('@/components/menu/NavMenu'),
    NavigationHeader: () => import('@/components/HeaderFooter/NavigationHeader/NavigationHeaderTheme0'),
    TopNav: () => import('@/components/HeaderFooter/TopNav/TopNav'),
    TopHeader: () => import('@/components/HeaderFooter/TopHeader/TopHeader'),
    LandingContent: () => import('@/components/landing/LandingContent'),
    Footer: () => import('@/components/HeaderFooter/Footer'),
  },
  mixins: [bannerImageDestination, mixinTheme],
  computed: {
    ...mapGetters({
      isLanding: 'GET_IS_LANDING_PAGE',
      landingInfo: 'GET_LANDING_INFO',
      device: 'GET_DEVICE',
    }),
  },
  async created() {
    this.$store.commit('SET_CURRENT_PAGE', 'landing');
    this.$store.dispatch('FETCH_SUBJECT_CATEGORY');
    await this.$store.dispatch('FETCH_DEAL_ITINERARY');
    await this.fetchBannerImageByDestination();
  },
  data() {
    return {
      preferDestCode: '',
      preferDealType: '',
    };
  },
  mounted() {
    window.sessionStorage.setItem('landing-url', window.location.search);
    this.$i18n.locale = 'he';
    this.$store.dispatch('UPDATE_LANGUAGE', this.$i18n.locale);
    window.sessionStorage.setItem('language', this.$i18n.locale);
    this.addGoogleAnalytice();
    this.setCookie();

    // get prefered Destination and dealType
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('preferDestCode')) {
      this.preferDestCode = urlParams.get('preferDestCode');
    }
    if (urlParams.has('preferDealType')) {
      this.preferDealType = urlParams.get('preferDealType');
    }
  },
  methods: {
    addGoogleAnalytice() {
      // const head = document.getElementsByTagName('head')[0];
      // const body = document.getElementsByTagName('body')[0];
      // body.insertAdjacentHTML('afterbegin', '<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WTKPQ72" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>');
    },
    setCookie() {
      const d = new Date();
      d.setTime(d.getTime() + (30 * 24 * 60 * 60 * 1000));
      const expires = `expires=${d.toUTCString()}`;
      document.cookie = `utm_source = ${this.landingInfo.utm_source}; ${expires} ;path=/`;
    },
  },
};
</script>

<style scoped>

</style>
